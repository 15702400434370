import React, { Component } from 'react';
import { createRoom, joinRoom } from '../api';

import './Entrance.css';

class Entrance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerName: '',
            roomCode: ''
        };

        this.handlePlayerNameChange = this.handlePlayerNameChange.bind(this);
        this.handleRoomCodeChange = this.handleRoomCodeChange.bind(this);
        this.handleCreateRoom = this.handleCreateRoom.bind(this);
        this.handleJoinRoom = this.handleJoinRoom.bind(this);
    }

    handlePlayerNameChange(event) {
        this.setState({ playerName : event.target.value });
    }

    handleRoomCodeChange(event) {
        this.setState({ roomCode : event.target.value.toUpperCase() });
    }

    handleCreateRoom() {
        createRoom(this.state.playerName);
    }

    handleJoinRoom() {
        joinRoom(this.state.playerName, this.state.roomCode);
    }

    render() {
        return (
            <div className="Entrance">
                <div className="logo"><div className="title">buzzman<span className="version">v0.5.1</span></div></div>
                <p><strong>buzzman is free and always will be.</strong> To support its creators, consider playing BPtrivia's <a href="http://bptrivia.com/dashboard" target="new">daily competitive league.</a></p>
                <input type="text" placeholder="Enter Your Name" value={this.state.playerName} onChange={this.handlePlayerNameChange} spellCheck={false} />
                {this.state.playerName && (<button onClick={this.handleCreateRoom}>Create Room</button>)}
                <div className="divider"></div>
                {this.state.playerName && (
                    <div>
                        <input type="text" placeholder="Room Code" value={this.state.roomCode} onChange={this.handleRoomCodeChange} spellCheck={false} />
                        {this.state.roomCode && (<button onClick={this.handleJoinRoom}>Join Room</button>)}
                    </div>
                )}
                <div className="divider"></div>
                <div className="bot-logo">
                    <a href="https://bptrivia.com" target="_blank" className="bpt-logo"></a>
                </div>
            </div>
        );
    }
}

export default Entrance;
