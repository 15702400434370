import React, { Component, useEffect } from 'react';
import './Buzzer.css';
import { buzz, buzzClear, socketId } from "../api";

import ReactHowler from 'react-howler';
import buzzSound from "../sounds/buzzSound.mp3";

class Buzzer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeLeft : 50,
            timerInterval : 0,
            canClear: true,
        };

        this.handleBuzz = this.handleBuzz.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.keyBuzz = this.keyBuzz.bind(this);
    }

    decrementTimer() {
        if (this.state.timeLeft > 0) {
            setTimeout(() => {
                this.setState({
                    timeLeft: this.state.timeLeft - 1,
                });
                this.decrementTimer();
            }, 100)
        } else {
            this.setState({
                timeLeft: 50
            });
        }
    }

    handleBuzz() {
        if (!this.props.room.isBuzzed) {
            buzz(this.props.room.id);
            this.decrementTimer();
            this.setState({
                canClear: false,
            })

            setTimeout(() => {
                this.setState({
                    canClear: true,
                });
            }, 1005)
        }
    }

    keyBuzz(event) {
        if (event.keyCode === 32) {
            if (!this.props.room.isBuzzed) {
                this.handleBuzz();
            } else if (this.props.room.isBuzzed && this.props.room.lastBuzzId === socketId()) {
                this.handleClear();
            }
        }
    }

    handleClear() {
        if (this.state.canClear) {
            this.setState({
                timeLeft: 0,
            });

            buzzClear(this.props.room.id);
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.keyBuzz, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyBuzz, false);
    }

    componentDidUpdate() {
        document.title =  this.props.room.isBuzzed ?  this.props.room.lastBuzzName + " Buzzed" : "Buzzman | Room";
    }

    render() {
        let timeLeft = (this.state.timeLeft / 10).toFixed(1);

        return (
            <div className="Buzzer">
                {this.props.room.isBuzzed && this.props.room.lastBuzzId === socketId() && (
                    <div className={`buzz-button is-buzzing ${!this.state.canClear ? "no-clear" : ""}`} onClick={this.handleClear}>
                        <span className="button-text">{timeLeft}</span>
                    </div>
                )}
                {this.props.room.isBuzzed && this.props.room.lastBuzzId !== socketId() && (
                    <div className="buzz-button locked">
                        <span className="button-text">{this.props.room.lastBuzzName} BUZZED</span>
                    </div>
                )}
                {!this.props.room.isBuzzed && (
                    <div className="buzz-button" onClick={this.handleBuzz}>
                        <span className="button-text">BUZZ</span>
                    </div>
                )}
            </div>
        );
    }
}

export default Buzzer;
