import io from 'socket.io-client';

var socket;

// Heroku Check.
socket = io();
// socket = io("http://localhost:3004");

function createRoom(playerName) {
    socket.emit('createRoom', playerName);
}

function joinRoom(playerName, roomCode) {
    socket.emit('joinRoom', { playerName : playerName, roomId : roomCode });
}

function roomSubscriber(callback) {
    socket.on('roomChange', (room) => callback(room, socket.id));
    socket.on('disconnect', () => callback({}, 0));
}

function buzz(roomCode) {
    socket.emit('buzz', roomCode);
}

function buzzClear(roomCode) {
    socket.emit('clear', roomCode);
}

function socketId() {
    return socket.id;
}

export { createRoom, joinRoom, roomSubscriber, buzz, buzzClear, socketId }